<template>
   <div id="news-container" v-if="content?.acf">
      <div class="title-wrapper">
         <div class="container">
            <span class="static-title">
               {{ content.acf.polylanguage[$i18n.locale].title }}
            </span>
            <div class="static-date">
               {{ content.date | formatDate }}
            </div>
         </div>
      </div>
      <div class="container">
         <div class="col-8">
            <div class="static-content" v-html="content.acf.polylanguage[$i18n.locale].content"></div>
         </div>
      </div>
   </div>
</template>

<script>
import * as RestApi from "@/service/rest.api";

export default {
   name: "StaticPage",
   data() {
      return {
         content: null,
      };
   },
   created() {
      RestApi.news(this.$route.params.slug).then((res) => {
         this.content = res[0];
         console.log(res[0]);
      });
   },
};
</script>

<style lang="scss">
#news-container {
   // padding: 2rem 0;

   .title-wrapper {
      padding-top: 6rem;
      padding-bottom: 2rem;
      background-color: #f4f4f4;
      .static-title {
         display: block;
         font-weight: 600;
         margin-bottom: 1rem;
         font-size: 48px;
         line-height: 1.125;
      }
   }

   .static-content {
      font-size: 18px;
      line-height: 1.8;
      font-weight: 400;
      font-style: normal;
      padding: 0.75rem 0;
      margin-bottom: 6rem;
   }
}
</style>
