<template>
   <div id="cookie-popup-wrapper" v-if="!isCookiesAccepted">
      <inline-svg :src="require('@/assets/img/ico_cookie.svg')" width="52" height="52" fill="#363636" aria-label="Instagram"></inline-svg>
      <span class="cookie-title">{{ $t("cookies.title") }}</span>
      <span class="cookie-text" v-html="$t('cookies.text')"></span>
      <div class="cookies-details" v-if="showDetails">
         <span class="cookies-allowance"> {{ $t("cookies.question") }} </span>
         <div class="cookies-allowance-wrapper">
            <div class="cookies-allowance-checkbox-wrapper">
               <!-- Wesentliche -->
               <input type="checkbox" name="wesentliche" id="wesentliche" disabled checked />
               <label for="wesentliche">{{ $t("cookies.essentials") }}</label>
            </div>
            <div class="cookies-allowance-checkbox-wrapper">
               <!-- Analytics -->
               <input type="checkbox" name="analytics" id="analytics" v-model="analytics" />
               <label for="analytics">{{ $t("cookies.analytics") }}</label>
            </div>
            <div class="cookies-allowance-checkbox-wrapper">
               <!-- Marketing -->
               <input type="checkbox" name="marketing" id="marketing" v-model="marketing" />
               <label for="marketing">{{ $t("cookies.marketing") }}</label>
            </div>
         </div>
      </div>
      <div id="cookie-actions">
         <button class="gts-secondary" @click="handlerDeclineCookies">{{ $t("cookies.deny") }}</button>
         <button class="gts-primary" @click="handlerAllowCookies">{{ $t("cookies.allow") }}</button>
      </div>
   </div>
</template>

<script>
import Vue from "vue";
import VueAnalytics from "vue-analytics";
// import moment from "moment";
import InlineSvg from "vue-inline-svg";
export default {
   name: "CookiePopup",
   components: {
      InlineSvg,
   },
   data() {
      return {
         isCookiesAccepted: false,
         analytics: true,
         marketing: true,
         showDetails: false,
      };
   },
   mounted() {
      // Check Controls

      const allowCookies = document.cookie.includes("allowCookies=true");
      const allowAnalytics = document.cookie.includes("allowAnalytics=true");
      // const allowMarketing = document.cookie.includes("allowMarketing=true");

      //Cookie Controls
      !allowCookies ? (this.isCookiesAccepted = false) : (this.isCookiesAccepted = true);

      if (allowAnalytics) {
         Vue.use(VueAnalytics, {
            id: "G-D2BJW59Y0S",
         });
      }

      //Helper

      // console.log(`AllowCookies: ${allowCookies}, AllowAnalytics: ${allowAnalytics}, AllowMarketing: ${allowMarketing} `);
   },
   methods: {
      handlerAllowCookies() {
         document.cookie = ` allowCookies=true;  SameSite=Lax;  path=/`;
         document.cookie = ` allowAnalytics=${this.analytics};  SameSite=Lax;  path=/`;
         document.cookie = ` allowMarketing=${this.marketing};  SameSite=Lax;  path=/`;
         // console.log(document.cookie);
         window.location.reload();
      },
      handlerDeclineCookies() {
         this.showDetails = true;
      },
   },
};
</script>

<style lang="scss">
#cookie-popup-wrapper {
   position: fixed;
   background: #fff;
   border: 1px solid #ccc;
   box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
   bottom: 1.25rem;
   left: 1.25rem;
   margin: auto;
   width: 600px;
   padding: 20px 26px;
   display: flex;
   flex-direction: column;
   align-items: center;
   border-radius: 0.5rem;
   z-index: 9999;

   @media (max-width: 576px) {
      width: 100%;
      left: 0;
   }

   a {
      margin-top: 0.5rem;
      color: #33b8ff;
   }

   #cookie-actions {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-top: 1.5rem;
      margin-bottom: 0.75rem;

      button {
         margin-bottom: 1rem;
      }

      @media (max-width: 576px) {
         flex-direction: column;
      }
   }

   span {
      color: #363636;
      &.cookie-title {
         font-weight: 600;
         font-size: 1.5rem;
         margin-top: 1rem;
         margin-bottom: 0.5rem;
         text-align: center;
      }
      &.cookie-text {
         font-size: 1rem;
         text-align: center;
         padding-bottom: 0.5rem;
      }
   }

   .highlighted {
      text-decoration: underline;
      color: red;
   }

   .cookies-details {
      border-top: 1px dashed #ccc;
      padding-top: 1rem;
      margin-top: 1rem;
      padding-left: 2rem;
      padding-right: 2rem;
      display: flex;
      flex-direction: column;

      .cookies-allowance {
         font-size: 1.125rem;
         font-weight: 500;
         width: 100%;
         display: block;
         text-align: center;
         color: #33b8ff;
      }
      .cookies-allowance-wrapper {
         margin-top: 1rem;
         display: flex;
         justify-content: space-around;

         .cookies-allowance-checkbox-wrapper {
            cursor: pointer;
            label {
               cursor: pointer;
            }
         }
      }
   }
}
</style>
