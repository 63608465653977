import axios from "axios";

//base --> http://beta-wordpress.gtsfly.de/wp-json

const PRIMARY_MENU_ENDPOIONT = "/menus/v1/locations/primary";
const HOMEPAGE_ENDPOINT = "/wp/v2/pages/56?acf_format=standard";
const CAROUSEL_ENDPOINT = "/wp/v2/slider?acf_format=standard";
const ANNOUNCEMENTS_ENDPOINT = "/wp/v2/announcements?acf_format=standard";
const NEWS_ENDPOINT = "/wp/v2/news?acf_format=standard";
// wp-admin --> Contact {contact form}
const CONTACT_FORM = "/contact-form-7/v1/contact-forms/183/feedback";
const APPLICATION_FORM = "/contact-form-7/v1/contact-forms/194/feedback";

//Static Pages
// const PRIVACY_ENDPOINT = "/wp/v2/pages/210?acf_format=standard";
// const COOKIE_ENDPOINT = "/wp/v2/pages/216?acf_format=standard";

const PUBLIC_HTTP = () => {
   const instance = axios.create({
      // baseURL: process.env.VUE_APP_API_NAME == "LOCAL" ? undefined : process.env.VUE_APP_API_BASE_URL,
      baseURL: process.env.VUE_APP_API_BASE_URL,
   });

   console.log("PUBLIC_HTTP", process.env);

   // Intercept requests to add a unique query parameter
   instance.interceptors.request.use((config) => {
      config.params = config.params || {};
      config.params.t = new Date().getTime(); // Add timestamp as a query parameter
      return config;
   });

   return instance;
};

export const primaryMenu = () => {
   return PUBLIC_HTTP()
      .get(PRIMARY_MENU_ENDPOIONT)
      .then((res) => res.data.items)
      .catch((error) => {
         if (error.response.status === 401) {
            console.log("401");
         } else {
            console.log("error from utils");
         }
      });
};
export const homePage = () => {
   return PUBLIC_HTTP()
      .get(HOMEPAGE_ENDPOINT)
      .then((res) => res.data)
      .catch((error) => {
         if (error.response.status === 401) {
            console.log("401");
         } else {
            console.log("error from utils");
         }
      });
};
export const homepageCarousel = () => {
   return PUBLIC_HTTP()
      .get(CAROUSEL_ENDPOINT)
      .then((res) => res.data)
      .catch((error) => {
         if (error.response.status === 401) {
            console.log("401");
         } else {
            console.log("error from utils");
         }
      });
};
export const homepageAnnouncements = () => {
   return PUBLIC_HTTP()
      .get(ANNOUNCEMENTS_ENDPOINT)
      .then((res) => res.data)
      .catch((error) => {
         if (error.response.status === 401) {
            console.log("401");
         } else {
            console.log("error from utils");
         }
      });
};
export const homepageNews = () => {
   return PUBLIC_HTTP()
      .get(NEWS_ENDPOINT)
      .then((res) => res.data)
      .catch((error) => {
         if (error.response.status === 401) {
            console.log("401");
         } else {
            console.log("error from utils");
         }
      });
};

export const contactForm = (form) => {
   const config = {
      headers: {
         "Content-Type": "multipart/form-data",
      },
   };
   return PUBLIC_HTTP()
      .post(CONTACT_FORM, form, config)
      .then((res) => res.data)
      .catch((error) => {
         if (error.response.status === 401) {
            console.log("401");
         } else {
            console.log("error from utils");
         }
      });
};
export const applicationForm = (form) => {
   const config = {
      headers: {
         "Content-Type": "multipart/form-data",
      },
   };
   return PUBLIC_HTTP()
      .post(APPLICATION_FORM, form, config)
      .then((res) => res.data)
      .catch((error) => {
         if (error.response.status === 401) {
            console.log("401");
         } else {
            console.log("error from utils");
         }
      });
};

//should be the same name with router
export const staticPage = (pageID) => {
   return PUBLIC_HTTP()
      .get(`/wp/v2/pages/${pageID}?acf_format=standard`)
      .then((res) => res.data)
      .catch((error) => {
         if (error.response.status === 401) {
            console.log("401");
         } else {
            console.log("error from utils");
         }
      });
};
//should be the same name with router
export const news = (slug) => {
   return (
      PUBLIC_HTTP()
         .get(`/wp/v2/news?slug=${slug}`)
         // https://wordpress.gtsfly.de/wp-json/wp/v2/news?slug=mesafeyi-azalttik
         .then((res) => res.data)
         .catch((error) => {
            if (error.response.status === 401) {
               console.log("401");
            } else {
               console.log("error from utils");
            }
         })
   );
};
