<template>
   <div class="component-header-container">
      <span class="comp-title">{{ infos.title }}</span>
      <span class="comp-sub-title">{{ infos.subTitle }} </span>
   </div>
</template>

<script>
export default {
   name: "ComponentHeader",
   props: {
      infos: Object,
   },
};
</script>

<style></style>
