<template>
   <div id="static-header-container">
      <div class="static-header-inner-wrapper container">
         <div class="static-header-title-wrapper col-12 col-md-6">
            <span class="title-one">{{ $t("slide.title1") }}</span>
            <span class="title-two">{{ $t("slide.title2") }}</span>
            <div class="static-header-content">
               <p>{{ $t("slide.content1") }}</p>
               <p>{{ $t("slide.content2") }}</p>
            </div>

            <div class="static-header-cta" @click="handlerApplicationForm">
               <div class="cta-text-wrapper">
                  <span class="cta-main">{{ $t("slide.cta1") }}</span>
                  <span class="cta-helper">{{ $t("slide.cta2") }}</span>
               </div>
               <div class="cta-icon-wrapper">
                  <inline-svg :src="require('@/assets/img/ico_eye.svg')" width="34" height="24" fill="#33b8ff" aria-label="Facebook"></inline-svg>
               </div>
            </div>
         </div>
         <img src="@/assets/img/3d_keyart.png" id="keyart" class="d-none d-md-flex" />
      </div>
   </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
export default {
   name: "StaticHeader",
   components: {
      InlineSvg,
   },

   data() {
      return {};
   },
   methods: {
      handlerApplicationForm() {
         this.$store.state.applicationForm = true;
      },
   },
};
</script>

<style lang="scss" scoped>
#static-header-container {
   //  background-color: #fff;
   height: 792px;
   position: relative;
   border-bottom: 1px solid #ccc;

   .static-header-inner-wrapper {
      // background: lightblue;
      height: 100%;

      .static-header-title-wrapper {
         padding-top: 8rem;
         display: flex;
         flex-direction: column;
         span.title-one {
            color: #363636;

            font-size: 48px;
            font-weight: 800;
         }
         span.title-two {
            color: #33b8ff;
            font-size: 48px;
            font-weight: 400;
         }
         .static-header-content {
            margin-top: 1.5rem;
            p {
               font-size: 1.125rem;
               color: #363636;
               line-height: 1.85rem;
               line-height: 1.55;
               padding: 0;
               margin: 0;
               margin-bottom: 0.75rem;
               max-width: 420px;
            }
         }
         .static-header-cta {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            background-color: #33b8ff;
            width: fit-content;
            padding: 1rem 2rem;
            border-radius: 24rem;
            margin-top: 3rem;
            .cta-text-wrapper {
               display: flex;
               flex-direction: column;
               span {
                  color: #fff;
                  &.cta-main {
                     font-size: 1.25rem;
                     font-weight: 500;
                     padding-bottom: 0.25rem;
                  }
                  &.cta-helper {
                     font-size: 0.85rem;
                     font-weight: 400;
                  }
               }
            }
            .cta-icon-wrapper {
               $square: 48px;
               width: $square;
               height: $square;
               background: #fff;
               border-radius: 50%;
               display: flex;
               align-items: center;
               justify-content: center;
               margin-left: 1.5rem;
            }

            &:hover {
               background-color: #145fda;

               .cta-icon-wrapper {
                  background: transparent !important;
                  transform: scale(1.4);
                  // border: 2px dotted #fff;

                  svg {
                     filter: brightness(10);
                  }
               }
            }
         }
      }
   }
}
</style>

<style lang="scss" scoped>
#keyart {
   width: 1300px;
   position: absolute;
   z-index: -1;
   top: 0;
   right: 0;
   @media only screen and (max-width: 1300px) {
      right: -250px;
      width: 1150px;
   }
   @media only screen and (max-width: 1400px) {
      right: -350px;
   }
}
</style>
