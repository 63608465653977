<template>
   <div id="static-page-wrapper">
      <div class="container">
         <section id="page-header">
            <span class="page-title">{{ title }}</span>
            <span class="page-subtitle">{{ subtitle }}</span>

            <div class="page-content" v-html="content"></div>
         </section>
         <hr />
      </div>
   </div>
</template>

<script>
// import * as RestApi from "@/service/rest.api";
export default {
   name: "ImpressumPage",
   data() {
      return {
         title: "Impressum",
         subtitle: "",
         content: `
			<p><strong>GTS Global Travel Services GmbH</strong></p>
<p>Stresemannstr. 342, 22761, Hamburg</p>
<p>22761 Hamburg</p>
<p>E-Mail: <a href="mailto:info@gtsfly.de">info@gtsfly.de</a></p>
<p><a href="https://www.gtsfly.de">https://www.gtsfly.de</a></p>
<p>Telefon: +49 40 22 61 38 37-0</p>
<p>Telefax: +49 40 22 61 38 37-4</p>
<p>&nbsp;</p>
<p><strong>Geschäftsführung</strong>: Serkan Yildiz und Ridvan Murteza</p>
<p><strong>Registerrecht</strong>: Amtsgericht Hamburg, HRB 118934</p>
<p><strong>USt-ID</strong>: DE278135005</p>

	`,
      };
   },
   created() {
      window.scrollTo(0, 0);
   },
   methods: {},
   watch: {
      "$route.name": {
         handler: function () {
            const self = this;
            self.handlerPageRefresh();
         },
      },
   },
};
</script>

<style lang="scss" scoped>
#static-page-wrapper {
   margin-top: 50px;
   margin-bottom: 100px;

   #page-header {
      display: flex;
      flex-direction: column;
      .page-title {
         font-size: 3rem;
         font-weight: 600;
         color: #363636;
         margin-bottom: 1.5rem;
      }
      .page-subtitle {
         font-size: 1.25rem;
         font-weight: 400;
         color: #363636;
         opacity: 0.6;
      }
   }

   hr {
      margin: 2rem 0;
   }
}
</style>

<style lang="scss">
.page-content {
   width: 100%;
   line-height: 1.8rem;

   p {
      color: #363636;
      font-size: 1rem;
      line-height: 1.65rem;
      b {
         font-size: 1.25rem;
         font-weight: 600;
      }
      a {
         color: #145fda;
         font-weight: 500;
      }
   }
   ol {
      li {
         color: #363636;
         font-size: 1rem;
         line-height: 1.65rem;
      }
   }
}
</style>
