<template>
   <div id="home">
      <StaticHeader v-if="false" />

      <Slider />

      <!-- We Are Growing -->
      <WeAreGrowing />

      <!-- Latest News & Announcements -->

      <LatestNewsAnnouncements />

      <!-- Vertical Tabs -->
      <VerticalTab />

      <OurAdvantages />

      <BigContentViever :data="businessModel" />

      <OurSolutionPartner />
      <OurProducts />
      <OurServices />

      <AgentReview :testimonials="testimonials" v-if="true" />

      <InfoBox :infoBox="infoBox" v-if="infoBox" />
   </div>
</template>

<script>
// @ is an alias to /src

import { homePage } from "@/service/rest.api.js";

import AgentReview from "@/components/HomePage/AgentReview.vue";
import InfoBox from "@/components/HomePage/InfoBox.vue";
import StaticHeader from "@/components/HomePage/StaticHeader.vue";
import WeAreGrowing from "@/components/HomePage/WeAreGrowing.vue";
import LatestNewsAnnouncements from "@/components/HomePage/LatestNewsAnnouncements.vue";
import VerticalTab from "@/components/common/VerticalTab.vue";

import OurServices from "@/components/HomePage/OurServices.vue";
import OurProducts from "@/components/HomePage/OurProducts.vue";
import OurSolutionPartner from "@/components/HomePage/OurSolutionPartner.vue";
import BigContentViever from "@/components/helpers/BigContentViewer.vue";
import OurAdvantages from "@/components/HomePage/OurAdvantages.vue";

import Slider from "@/components/HomePage/Slider.vue";

export default {
   name: "HomePage",
   components: {
      AgentReview,
      InfoBox,
      StaticHeader,
      WeAreGrowing,
      LatestNewsAnnouncements,
      VerticalTab,
      Slider,
      OurServices,
      OurProducts,
      OurSolutionPartner,
      BigContentViever,
      OurAdvantages,
   },
   data() {
      return {
         testimonials: null,
         infoBox: false,
         counter: false,
         imgurl: null,
         downloadUrl: null,
         businessModel: {
            content: this.$i18n.t("businessModel.content"),

            boxes: [
               {
                  title: this.$i18n.t("businessModel.boxes[0].title"),
                  content: this.$i18n.t("businessModel.boxes[0].content"),
               },
               {
                  title: this.$i18n.t("businessModel.boxes[1].title"),
                  content: this.$i18n.t("businessModel.boxes[1].content"),
               },
               {
                  title: this.$i18n.t("businessModel.boxes[2].title"),
                  content: this.$i18n.t("businessModel.boxes[2].content"),
               },
            ],
         },
      };
   },
   created() {
      const self = this;

      // this.$i18n.locale = "de";
      // console.log(this.$i18n.locale);

      self.$store.state.header = "light";
      homePage().then((res) => {
         self.testimonials = res.acf.testimonials;
         self.infoBox = res.acf.bigInfoText;
         self.counter = res.acf.counter;
      });

      // self.$router.push("/");
   },
};
</script>

<style lang="scss">
#informations {
   padding: 8rem 0;
   font-family: "Merriweather", serif;
   font-size: 2.25rem;
   line-height: 3.5rem;
   text-align: center;
   color: #363636;
   font-weight: 300;
   span {
      font-weight: 600;
   }
}
</style>
