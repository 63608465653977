import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state() {
    return {
      sitemode: null,
      applicationForm: false,
      cookieAllowance: false,
      header: "light",
    };
  },
});

export default store;
