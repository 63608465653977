<template>
  <div id="jumbotron-wrapper" class="container">
    <section class="jumbotron-left col-6">
      <h1 class="jumbotron-title">
        {{ data.title }}
        <span class="jumbotron-subtitle">{{ data.subtitle }}</span>
      </h1>
      <p class="jumbotron-p1" v-html="data.content"></p>
      <p class="jumbotron-p2" v-html="data.content2"></p>
    </section>
    <section class="jumbotron-right col-6">
      <slot></slot>
    </section>
  </div>
</template>

<script>
export default {
  name: "JumbotronComp",
  props: {
    data: Object,
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
#jumbotron-wrapper {
  padding: 5rem 0;
  display: flex;
  justify-content: space-between;
  .jumbotron-left {
    display: flex;
    flex-direction: column;
    width: 45%;
    .jumbotron-title {
      display: flex;
      flex-direction: column;
      font-size: 3.5rem;
      font-weight: 500;
      color: #363636;
      padding: 0;
      margin: 0;
      margin-bottom: 0.5rem;

      .jumbotron-subtitle {
        color: #145fda;
        font-weight: 700;
      }
    }
    p {
      margin: 0.75rem 0;
    }
    p.jumbotron-p1 {
      font-size: 1.25rem;
      color: #363636;
      line-height: 1.85rem;
      line-height: 1.55;

      span.jumbotron-mini-information {
        font-size: 1rem;
        // background: #f0f6ff;
        color: #363636;
        // padding: 0 0.25rem;
        font-family: "Roboto Mono", monospace;
      }
    }
    p.jumbotron-p2 {
      font-size: 1.25rem;
      color: #363636;
      line-height: 1.85rem;
      line-height: 1.55;
      opacity: 0.75;
      font-size: 1rem;
    }
  }
}
</style>
