<template>
   <div id="announcements-and-news-container" class="container">
      <span class="home-page-section-title">{{ $t("announcements.title") }}</span>

      <div class="row">
         <div class="col-12 col-md-4">
            <div class="announcements-wrapper">
               <div class="announcement-icon-wrapper">
                  <inline-svg :src="require('@/assets/img/icon-announcements.svg')" width="96" height="67" fill="#33b8ff" aria-label="Facebook"></inline-svg>
               </div>

               <template v-for="(announcement, anouncementIndex) in announcements.lists">
                  <div class="announcement-inner-wrapper" :key="anouncementIndex" v-if="anouncementIndex == announcements.active">
                     <span class="announcement-date">
                        {{ announcement.acf?.common.date }}
                     </span>

                     <span class="announcement-title">
                        {{ announcement.acf?.polylanguage[LocaleLang].title }}
                     </span>

                     <div class="announcement-content" v-html="announcement.acf?.polylanguage[LocaleLang].content"></div>

                     <!-- {{ slide.acf.polylanguage[$i18n.locale].title }} -->
                  </div>
               </template>

               <div class="announcements-bullets-wrapper">
                  <template v-for="(bullet, bulletIndex) in announcements.lists">
                     <div class="bullet" :key="bulletIndex" @click="handlerSetBullet(bulletIndex)" :class="bulletIndex == announcements.active ? 'active-bullet' : ''"></div>
                  </template>
               </div>
            </div>
         </div>
         <div class="col-12 col-md-8">
            <div class="news-wrapper">
               <div class="row">
                  <template v-for="(news, newsIndex) in news.lists">
                     <div class="news-inner-wrapper col-12 col-md m-0 my-1" :key="newsIndex" v-if="newsIndex < 3">
                        <div class="news-image-wrapper">
                           <img :src="news.acf.common.image" alt="" />
                        </div>
                        <span class="news-date"> {{ news.acf.common.date }} - {{ newsIndex }} </span>
                        <span class="news-title">
                           {{ news.acf.polylanguage[LocaleLang].title }}
                        </span>
                        <div class="news-content" v-html="news.acf.polylanguage[LocaleLang].brief"></div>
                        <!-- <a :href="news._links.self[0].href">news._links.self[0].href</a> -->
                        <router-link :to="'/news/' + news.slug" class="news-read-more">{{ $t("readMore") }}</router-link>
                     </div>
                  </template>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { homepageAnnouncements } from "@/service/rest.api.js";
import { homepageNews } from "@/service/rest.api.js";
import InlineSvg from "vue-inline-svg";
export default {
   name: "LatestNewsAnnouncements",
   components: {
      InlineSvg,
   },

   data() {
      return {
         announcements: {
            lists: [],
            active: 0,
         },
         news: {
            lists: [],
         },
      };
   },
   mounted() {
      homepageAnnouncements().then((res) => {
         this.handlerAnnouncementGenerator(res);
      });
      homepageNews().then((res) => {
         this.handlerNewsGenerator(res);
      });
   },
   computed: {
      LocaleLang() {
         // return this.$i18n.locale == "en" ? "tr" : this.$i18n.locale;
         return this.$i18n.locale;
      },
      NewsList() {
         if (this.$i18n.locale == "tr") {
            return this.newsListtr;
         } else {
            return this.newsListde;
         }
      },
      Announcements() {
         if (this.$i18n.locale == "tr") {
            return this.announcementstr;
         } else {
            return this.announcementde;
         }
      },
   },
   methods: {
      handlerAnnouncementGenerator(announcements) {
         this.announcements.lists = announcements;
      },
      handlerNewsGenerator(news) {
         this.news.lists = news;
      },
      handlerApplicationForm() {
         this.$store.state.applicationForm = true;
      },
      handlerSetBullet(bulletIndex) {
         this.announcements.active = bulletIndex;
      },
      handlerReadMore(index) {
         this.NewsList[index].readMore = true;
      },
      handlerGoBack(index) {
         this.NewsList[index].readMore = false;
      },
   },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
   transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
   opacity: 0;
}
</style>

<style lang="scss" scoped>
#announcements-and-news-container {
   .announcements-wrapper {
      background-color: #2265c3;
      border-radius: 1rem;
      padding: 3.5rem 3rem;
      box-sizing: content-box;
      display: flex;
      flex-direction: column;
      height: 100%;
      box-sizing: border-box;
      position: relative;

      @media (max-width: 576px) {
         height: auto;
      }

      .announcement-icon-wrapper {
         flex-shrink: 0;
      }

      .announcement-inner-wrapper {
         display: flex;
         flex-direction: column;
         margin-top: 2.5rem;

         span {
            color: #fff;

            &.announcement-date {
               font-size: 0.85rem;
               margin-bottom: 0.5rem;
            }
            &.announcement-title {
               font-size: 32px;
               font-weight: 600;
               color: #fff;
            }
         }

         .announcement-content {
            font-size: 16px;
            margin-top: 1rem;
         }
      }

      .announcements-bullets-wrapper {
         display: flex;
         position: absolute;
         bottom: 1.5rem;
         right: 1rem;
         .bullet {
            display: block;
            cursor: pointer;
            $bullet: 12px;
            width: $bullet;
            height: $bullet;
            background: #fff;
            border-radius: 50%;
            margin: 0 0.25rem;
            opacity: 0.5;

            &.active-bullet {
               opacity: 1;
            }
         }
      }
   }
   .news-wrapper {
      .row {
         gap: 1rem;
      }
      .news-inner-wrapper {
         border: 1px solid #ccc;
         width: 330px;
         padding: 1rem;
         padding-bottom: 2rem;
         border-radius: 8px;
         display: flex;
         flex-direction: column;
         margin: 0 1rem;

         @media (max-width: 1000px) {
            width: 100% !important;
         }

         .news-image-wrapper {
            width: 100%;
            border-radius: 8px;
            overflow: hidden;
            margin-bottom: 1rem;
            img {
               width: 100%;
               height: 100%;
            }
         }

         .news-date {
            color: #808080;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 0.75rem;
         }
         .news-title {
            color: #202a38;
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 0.75rem;
            min-height: 57px;
         }

         .news-content {
            color: #808080;
            line-height: 26px;
         }
         .news-read-more {
            color: #33b8ff;
            text-decoration: underline;
            margin-top: 2rem;
         }
      }
      .col {
         border: 1px solid #ccc;
      }
   }
}
</style>

<style lang="scss" scoped>
#announcements-and-news-contaadiner {
   padding-top: 130px;
   .an-inner-wrapper {
      display: flex;
      .announcements-container {
         background: #2e67b8;
         padding: 2.5rem 3.5rem;
         border-radius: 1rem;
         width: 432px;
         box-sizing: content-box;
         display: flex;
         flex-direction: column;
         height: 574px;
         box-sizing: border-box;

         .announcements-bullets-container {
            display: flex;
            justify-content: space-between;
            margin-top: 2rem;
            align-items: center;
            position: absolute;

            .show-details-wrapper {
               span {
                  color: #fff;
               }
            }

            .announcements-bullets-wrapper {
               display: flex;
               .bullet {
                  display: block;
                  cursor: pointer;
                  $bullet: 12px;
                  width: $bullet;
                  height: $bullet;
                  background: #fff;
                  border-radius: 50%;
                  margin: 0 0.25rem;
                  opacity: 0.5;

                  &.active-bullet {
                     opacity: 1;
                  }
               }
            }
         }

         .announcements-wrapper {
            margin-top: 1.5rem;
            .announcement-item {
               height: 300px;
               overflow: hidden;
               span.announcement-title {
                  color: #fff;
                  font-size: 1.6rem;
                  line-height: 32px;
                  font-weight: 500;
                  margin-bottom: 1.25rem;
                  display: block;
               }
               .announcement-content {
                  overflow-y: scroll;
                  height: 150px;
                  padding-bottom: 5rem;

                  a {
                     color: #fff;
                     text-decoration: underline;
                  }
               }
            }
         }
      }
      .news-container {
         background: white;
         width: 100%;
         margin: 0 1rem;

         .news-items-container {
            display: flex;
            height: 100%;
            // background: lightcyan;
            // justify-content: space-around;
            .news-item {
               border: 1px solid #ccc;
               width: 330px;
               padding: 1rem;
               border-radius: 0.5rem;
               display: flex;
               flex-direction: column;
               justify-content: space-between;
               margin: 0 1rem;

               .news-top-section {
                  display: flex;
                  flex-direction: column;
                  .news-item-image-container {
                     display: flex;
                     border-radius: 0.5rem;
                     width: 100%;
                     height: 250px;
                     overflow: hidden;
                     margin-bottom: 1rem;

                     img {
                        width: 100%;
                        height: 100%;
                     }
                  }
                  .news-item-date {
                     color: #808080;
                     font-size: 0.75rem;
                  }
                  .news-item-title {
                     font-size: 1.25rem;
                     font-weight: 600;
                     color: #363636;
                     margin-top: 0.75rem;
                     line-height: 26px;
                  }
                  .news-item-content {
                     color: #808080;
                     line-height: 20px;
                     font-size: 0.9rem;
                     margin: 0.5rem 0;
                  }
               }
               .news-read-more {
                  color: #33b8ff;
                  font-weight: 500;
                  font-size: 1rem;
                  text-decoration: underline;
                  padding-bottom: 1.5rem;
                  cursor: pointer;
               }
            }
            .news-details-container {
               border: 1px solid #ccc;
               width: 330px;
               padding: 1rem;
               border-radius: 0.5rem;
               display: flex;
               flex-direction: column;
               justify-content: space-between;
               margin: 0 1rem;
               .news-item-wrapper {
                  display: flex;
                  flex-direction: column;
                  height: 100%;
                  justify-content: space-between;
                  .news-item-date {
                     color: #808080;
                     font-size: 1rem;
                  }
                  .news-item-title {
                     font-size: 1.25rem;
                     font-weight: 600;
                     color: #363636;
                     margin-top: 0.75rem;
                     line-height: 26px;
                  }
                  .go-back-container {
                     cursor: pointer;
                     // margin-top: 1rem;
                  }
               }
            }
         }
      }
   }
}
</style>

<style lang="scss">
div.announcement-content {
   p {
      color: #fff;
      font-weight: 300;
      margin-top: 0;
      margin-bottom: 0.5rem;
      line-height: 24px;

      a {
         color: #fff;
      }
   }
}
.news-item-content-details {
   height: 310px;
   overflow-y: scroll;
   a {
      color: #33b8ff;
   }
   li {
      margin-bottom: 0.5rem;
   }
}
.fade-enter-active {
   transition-duration: 0.1s;
   transition-timing-function: ease-in;
}

.fade-leave-active {
   transition-duration: 0.1s;
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.fade-enter-to,
.fade-leave {
   opacity: 1;
}

.fade-enter,
.fade-leave-to {
   opacity: 0;
}
</style>
