<template>
   <div id="we-are-growing-container" class="container">
      <span class="home-page-section-title">{{ $t("wearegrowing.title") }}</span>

      <div class="wrg-items-wrapper row">
         <div class="col" v-for="(item, index) in Items" :key="index">
            <div class="wrg-item">
               <div class="wrg-item-icon">
                  <inline-svg :src="require(`@/assets/img/${item.icon}`)" fill="#33b8ff" aria-label="Facebook"></inline-svg>
               </div>
               <span class="wrg-item-count">{{ item.count }}</span>
               <span class="wrg-item-text">{{ item.text }}</span>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
export default {
   name: "WeAreGrowing",
   components: {
      InlineSvg,
   },

   data() {
      return {
         items: [
            { count: "1500+", text: this.$i18n.t("wearegrowing.activeAgents"), icon: "icon-active-agents.svg" },
            { count: "1m+", text: this.$i18n.t("wearegrowing.totalFlights"), icon: "icon-airlines.svg" },
            { count: "600+", text: this.$i18n.t("wearegrowing.contractedHotels"), icon: "icon-bed.svg" },
            // npm{ count: 892, text: "Transfer Yaptık", icon: "icon-transfer.svg" },
         ],
      };
   },
   computed: {
      Items() {
         return [
            { count: "1500+", text: this.$i18n.t("wearegrowing.activeAgents"), icon: "icon-active-agents.svg" },
            { count: "1m+", text: this.$i18n.t("wearegrowing.totalFlights"), icon: "icon-airlines.svg" },
            { count: "600+", text: this.$i18n.t("wearegrowing.contractedHotels"), icon: "icon-bed.svg" },
            // npm{ count: 892, text: "Transfer Yaptık", icon: "icon-transfer.svg" },
         ];
      },
   },
   methods: {
      handlerApplicationForm() {
         this.$store.state.applicationForm = true;
      },
   },
};
</script>

<style lang="scss" scoped>
#we-are-growing-container {
   margin-top: 6rem;
   margin-bottom: 8rem;
   width: 100%;

   .wrg-items-wrapper {
      display: flex;

      @media (max-width: 576px) {
         flex-direction: column;
         padding: 0 1rem;
      }
      .wrg-item {
         background: #f7f9ff;
         width: 100%;
         // margin: 0 2rem;
         display: flex;
         flex-direction: column;
         justify-content: center;
         border-radius: 1.5rem;
         padding: 3rem 1rem;
         align-items: center;
         border: 1px solid transparent;
         // border: 1px solid black;
         transition-property: transform;
         transition-timing-function: ease-in-out;
         transition-duration: 0.1s;
         box-sizing: border-box;

         @media (max-width: 576px) {
            margin-bottom: 1rem;
         }

         span {
            display: block;
            width: 100%;
            color: #2e67b8;
            text-align: center;
            &.wrg-item-count {
               font-weight: 800;
               font-size: 3rem;
               margin-top: 1rem;
            }
            &.wrg-item-text {
               font-size: 1rem;
               font-weight: 400;
            }
         }
         .wrg-item-icon {
            height: 50px;
            display: flex;
            align-items: center;
         }

         &:hover {
            background: #fff;
            border: 1px solid #2e67b8;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
            transform: scale(1.05);
         }
      }
   }
}
</style>

<style lang="scss">
.home-page-section-title {
   color: #363636;
   margin-bottom: 4rem;
   font-weight: 500;
   font-size: 1.8rem;
   width: 100%;
   text-align: center;
   display: block;
}
</style>
