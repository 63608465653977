<template>
   <div class="accordion-menu-container">
      <ul>
         <li v-for="(item, index) in accordion" :key="index">
            <div class="accordion-collapsed-wrapper">
               <span class="accordion-title">{{ item.title }}</span>
               <!-- {{ item }} -->
               <inline-svg
                  @click="handlerSelectItem(index)"
                  :src="require(`@/assets/img/${item.status ? 'accordion_arrow_active' : 'accordion_arrow'}.svg`)"
                  width="32"
                  height="32"></inline-svg>
            </div>
            <div class="accordion-expanded-wrapper" v-if="item.status">
               <p>
                  {{ item.content }}
               </p>
            </div>
         </li>
      </ul>
   </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
export default {
   name: "AccordionContent",
   components: {
      InlineSvg,
   },
   props: {
      items: Array,
   },
   data() {
      return {
         accordion: [],
      };
   },
   created() {
      this.items.map((item, index) => {
         this.accordion.push({
            title: item.title,
            content: item.content,
            status: index == 0 ? true : false,
         });
      });
   },
   methods: {
      handlerSelectItem(el) {
         this.accordion.forEach((item, index) => {
            if (index == el) {
               item.status = true;
            } else {
               item.status = false;
            }
         });
      },
   },
};
</script>

<style lang="scss" scoped>
.accordion-menu-container {
   ul {
      border: 1px solid #ababab;
      border-radius: 1rem;

      li {
         border-bottom: 1px solid #ababab;
         padding: 1.5rem 2rem;

         &:last-child {
            border-bottom: none !important;
         }

         svg {
            cursor: pointer;
         }
         .accordion-collapsed-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span.accordion-title {
               font-weight: 500;
               font-size: 1.25rem;
               color: #363636;
            }
         }
         .accordion-expanded-wrapper {
            padding-top: 0.5rem;
            p {
               opacity: 0.6;
               padding-right: 2rem;
            }
         }
      }
   }
}
</style>
