<template>
   <div id="all-news-container">
      <div class="container">
         <div class="news-item" v-for="item in news" :key="item.id">
            <div class="news-image">
               <img :src="item.acf.common.image" alt="News Image" />
            </div>
            <div class="news-details">
               <h2>{{ item.acf.polylanguage[LocaleLang].title }}</h2>
               <p>{{ item.acf.polylanguage[LocaleLang].brief }}</p>

               <router-link :to="'/news/' + item.slug" class="news-read-more">{{ $t("readMore") }}</router-link>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import * as RestApi from "@/service/rest.api";

export default {
   name: "StaticPage",
   data() {
      return {
         news: [],
      };
   },
   created() {
      RestApi.homepageNews().then((res) => {
         this.news = res;
      });
   },
   computed: {
      LocaleLang() {
         // return this.$i18n.locale == "en" ? "tr" : this.$i18n.locale;
         return this.$i18n.locale;
      },
   },
};
</script>

<style lang="scss">
#all-news-container {
   display: flex;
   flex-direction: column;
   gap: 20px;
   margin: 2rem 0;
   margin-bottom: 5rem;

   .news-item {
      display: flex;
      // align-items: center;
      gap: 15px;
      border-bottom: 1px solid #ccc;
      padding-bottom: 20px;
      border: 1px solid #eee;
      padding: 1rem;
      border-radius: 1rem;
      margin-bottom: 1rem;

      .news-image {
         height: 110px;
         overflow: hidden;
         max-width: 130px;

         img {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }

      .news-details {
         flex-grow: 1;

         h2 {
            font-size: 18px;
            margin: 0 0 10px 0;
         }

         p {
            font-size: 14px;
            margin: 0 0 10px 0;
         }

         a {
            color: #007bff;
            text-decoration: none;

            &:hover {
               text-decoration: underline;
            }
         }
      }
   }
}
</style>
