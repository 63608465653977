<template>
  <div id="team-wrapper" class="container">our.story.wrapper</div>
</template>

<script>
export default {
  name: "OurStory",
};
</script>

<style></style>
