<template>
   <div class="our-products-main-wrapper">
      <div class="our-products container development">
         <ComponentHeader :infos="componentHeader" />

         <ul class="our-products-pills-wrapper">
            <li v-for="(pill, index) in pills" :key="index" :class="pill.status ? 'active' : ''" @click="handlerSelectPill(index)">
               {{ pill.name }}
            </li>
         </ul>

         <div class="our-products-pill-details" v-for="(pill, pillIndex) in pills" :key="pillIndex">
            <template v-if="pill.status">
               <div class="pill-wrapper">
                  <template v-for="(x, xIndex) in pill.content">
                     <div class="pill-inner-wrapper" :key="xIndex">
                        <span class="pill-title">
                           {{ x.title }}
                        </span>

                        <p class="pill-content">
                           {{ x.content }}
                        </p>
                     </div>
                  </template>
               </div>
            </template>
         </div>
      </div>
   </div>
</template>

<script>
import ComponentHeader from "@/components/helpers/ComponentHeader.vue";
export default {
   name: "OurServices",
   components: {
      ComponentHeader,
   },
   data() {
      return {
         componentHeader: {
            title: this.$i18n.t("ourproducts.compTitle"),
         },
         activePill: 1,
         pills: [
            {
               name: this.$i18n.t("ourproducts.pills.flight.title"),
               status: true,
               content: [
                  {
                     title: this.$i18n.t("ourproducts.pills.flight.content[0].title"),
                     content: this.$i18n.t("ourproducts.pills.flight.content[0].content"),
                  },
                  {
                     title: this.$i18n.t("ourproducts.pills.flight.content[1].title"),
                     content: this.$i18n.t("ourproducts.pills.flight.content[1].content"),
                  },

                  {
                     title: this.$i18n.t("ourproducts.pills.flight.content[2].title"),
                     content: this.$i18n.t("ourproducts.pills.flight.content[2].content"),
                  },
                  {
                     title: this.$i18n.t("ourproducts.pills.flight.content[3].title"),
                     content: this.$i18n.t("ourproducts.pills.flight.content[3].content"),
                  },
               ],
            },
            {
               name: this.$i18n.t("ourproducts.pills.hotels.title"),
               status: false,
               content: [
                  {
                     title: this.$i18n.t("ourproducts.pills.hotels.content[0].title"),
                     content: this.$i18n.t("ourproducts.pills.hotels.content[0].content"),
                  },
               ],
            },
            {
               name: this.$i18n.t("ourproducts.pills.others.title"),
               status: false,
               content: [
                  {
                     title: this.$i18n.t("ourproducts.pills.others.content[0].title"),
                     content: this.$i18n.t("ourproducts.pills.others.content[0].content"),
                  },
                  {
                     title: this.$i18n.t("ourproducts.pills.others.content[1].title"),
                     content: this.$i18n.t("ourproducts.pills.others.content[1].content"),
                  },
               ],
            },
         ],
      };
   },
   methods: {
      handlerSelectPill(el) {
         this.pills.forEach((item, index) => {
            if (index == el) {
               item.status = true;
            } else {
               item.status = false;
            }
         });
      },
   },
};
</script>

<style lang="scss" scoped>
.our-products-main-wrapper {
   border-bottom: 2px dashed #eee;
   border-top: 2px dashed #eee;
   @media (max-width: 576px) {
      border-bottom: 2px dashed #363636;
      border-top: 2px dashed #363636;
   }
}
.our-products {
   margin-top: 6rem !important;

   .our-products-pills-wrapper {
      text-align: center;
      margin-bottom: 1rem;
      margin-top: 2rem;
      li {
         display: inline-flex;
         padding: 0.5rem 2rem;
         border-width: 2px;
         border-style: solid;
         border-color: transparent;
         border-radius: 24rem;
         margin-right: 1rem;
         font-size: 1.125rem;
         cursor: pointer;

         &:hover {
            background-color: #eee;
         }

         &.active {
            border-color: #33b8ff;
            color: #33b8ff;
            &:hover {
               background-color: transparent !important;
            }
         }
      }
   }
   .our-products-pill-details {
      .pill-wrapper {
         display: flex;
         flex-wrap: wrap;

         .pill-inner-wrapper {
            width: 50%;
            flex-shrink: 0;
            // border: 1px solid;
            margin: 1rem 0;
            padding: 0 1rem;

            span.pill-title {
               font-weight: 500;
               font-size: 1.25rem;
               border-bottom: 1px dashed #ccc;
               padding-bottom: 0.75rem;
               width: 100%;
               display: inline-block;
            }
            p {
               opacity: 0.75;
               margin-top: 1rem;
               line-height: 1.5rem;
            }
         }
      }
   }
}
</style>
