<template>
   <div id="carousel-container">
      <div class="swiper" ref="carousel">
         <div class="swiper-wrapper">
            <!-- each slide -->
            <template v-for="(slide, index) in slider.slides">
               <div class="swiper-slide" :style="{ backgroundImage: handlerDynamicImage(slide.acf?.common.slider_image), backgroundColor: slide.acf?.common.background_color }" :key="index">
                  <div class="swiper-slider-inner-wrapper container">
                     <div class="title-wrapper">
                        <span class="slide-title"> {{ slide.acf?.polylanguage[LocaleLang].title }} </span>
                        <span class="slide-subtitle">{{ slide.acf?.polylanguage[LocaleLang].subtitle }} </span>
                     </div>

                     <div class="content-wrapper" v-html="slide.acf?.polylanguage[LocaleLang].content"></div>

                     <div class="slide-custom-button-container" v-if="slide.acf?.polylanguage[LocaleLang].add_custom_button" @click="handlerCustomCTA(slide.acf?.polylanguage[LocaleLang].custom_cta)">
                        <section class="button-left">
                           <span class="button-title">{{ slide.acf?.polylanguage[LocaleLang].button_label }}</span>
                           <span class="button-content">{{ slide.acf?.polylanguage[LocaleLang].button_description }}</span>
                        </section>
                        <section class="button-right" v-if="slide.acf?.polylanguage[LocaleLang].hasicon">
                           <div class="button-container">
                              <inline-svg :src="require('@/assets/img/ico_eye.svg')" width="34" height="24" fill="#202A38" aria-label="gotolink"></inline-svg>
                           </div>
                        </section>
                     </div>
                  </div>
               </div>
            </template>
         </div>
         <div class="swiper-pagination-carousel container"></div>
      </div>
   </div>
</template>

<script>
import Swiper, { Autoplay, Navigation, Pagination } from "swiper";

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay]);
import "swiper/css";
import InlineSvg from "vue-inline-svg";

import { homepageCarousel } from "@/service/rest.api.js";

export default {
   name: "SliderCarousel",
   components: {
      InlineSvg,
   },
   data() {
      return {
         slider: {
            init: null,
            slides: null,
         },
      };
   },

   mounted() {
      const self = this;

      homepageCarousel().then((res) => {
         self.slider.slides = res;
      });
   },
   computed: {
      LocaleLang() {
         // return this.$i18n.locale == "en" ? "tr" : this.$i18n.locale;
         return this.$i18n.locale;
      },
   },
   methods: {
      handlerDynamicImage(img) {
         return `url("${img}")`;
      },
      handlerCustomCTA(cta) {
         if (cta == "application_form_cta") {
            this.$store.state.applicationForm = true;
         }
      },
   },

   watch: {
      "slider.slides": {
         handler: function () {
            const self = this;
            setTimeout(function () {
               self.slider.init = new Swiper(self.$refs.carousel, {
                  scrollbar: false,
                  speed: 600,
                  autoplay: {
                     delay: 8000,
                     disableOnInteraction: false,
                  },
                  loop: true,
                  spaceBetween: 0,
                  pagination: {
                     el: ".swiper-pagination-carousel",
                     type: "bullets",
                     clickable: true,
                  },
               });
            }, 300);
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss">
#carousel-container {
   .content-wrapper {
      margin-top: 1.5rem;
      p {
         font-size: 1.125rem;
         color: #363636;
         line-height: 1.85rem;
         line-height: 1.55;
         padding: 0;
         margin: 0;
         margin-bottom: 0.75rem;
         max-width: 420px;
         @media (max-width: 576px) {
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 0.25rem;
         }
      }
   }
}
</style>
<style lang="scss">
.swiper-pagination-carousel {
   display: flex;
   position: relative;
   top: -100px;
   z-index: 99;

   .swiper-pagination-bullet {
      // border: 1px solid #ccc;
      width: 50px;
      height: 20px;
      display: flex;
      border-radius: 0;
      cursor: pointer;
      margin: 0 0.3rem;
      position: relative;

      &:before {
         content: "";
         position: absolute;
         top: 0;
         bottom: 0;
         margin: auto;
         border-radius: 2px;
         background: #d2e2f8;
         width: 100%;
         height: 3.5px;
      }

      &.swiper-pagination-bullet-active {
         &:before {
            background-color: #216dd9;
         }
      }
   }
}
</style>

<style lang="scss" scoped>
#carousel-container {
   height: 760px;

   .swiper {
      height: inherit;

      .swiper-wrapper {
         .swiper-slide {
            background-repeat: no-repeat;
            background-position-x: center;
            background-position-y: center;
            background-size: contain;
            border-bottom: 1px solid #d9d9d9;
            transition-property: all;
            transition-timing-function: ease-in-out;
            transition-duration: 0.5s;

            @media (max-width: 576px) {
               background-size: cover !important;
            }

            .swiper-slider-inner-wrapper {
               overflow: hidden;
               padding-top: 8rem;
               .title-wrapper {
                  display: flex;
                  flex-direction: column;
                  span {
                     font-size: 48px;

                     &.slide-title {
                        color: #363636;
                        font-weight: 800;
                     }
                     &.slide-subtitle {
                        color: #33b8ff;

                        font-weight: 400;
                     }
                  }
               }
               .slide-custom-button-container {
                  background-color: #33b8ff;
                  border-radius: 10rem;
                  display: inline-flex;
                  padding: 1rem 2rem;
                  margin-top: 3rem;
                  cursor: pointer;

                  &:hover {
                     background-color: #366ed2;
                  }

                  .button-left {
                     display: flex;
                     flex-direction: column;
                     margin-right: 1rem;

                     span {
                        color: #fff;
                        &.button-title {
                           font-size: 1.25rem;
                           font-weight: 500;
                        }
                        &.button-content {
                           font-size: 1rem;
                           font-weight: 400;
                        }
                     }
                  }
                  .button-right {
                     .button-container {
                        background: #fff;
                        width: 48px;
                        height: 48px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                     }
                  }
               }
            }
         }
      }
   }
}
</style>
