import Vue from "vue";
import VueI18n from "vue-i18n";
import { tr, de, en } from "./languages";

Vue.use(VueI18n);

const messages = {
   tr,
   de,
   en,
};

if (!localStorage.getItem("lang")) {
   let browserLang = Object.keys(messages).find((lang) => {
      return lang == navigator.language.substring(0, 2);
   });

   browserLang ? localStorage.setItem("lang", "de") : localStorage.setItem("lang", "de");
}

const i18n = new VueI18n({
   locale: localStorage.getItem("lang"),
   fallbackLocale: "de",
   messages,
});

export default i18n;
