<template>
  <div id="agent-application-callback-wrapper">
    <div class="agent-application-callback-container container">
      <span>Become an agent now to serve our products and services to your customers.</span>
      <button @click="handlerAgentApplicationForm">Agent Application</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AgentApllicationCallback",
  methods: {
    handlerAgentApplicationForm() {
      const self = this;
      self.$store.state.applicationForm = true;
    },
  },
};
</script>

<style lang="scss" scoped>
#agent-application-callback-wrapper {
  background-color: #145fda;
  padding: 3.5rem 0;
  margin-bottom: 5rem;

  .agent-application-callback-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    span {
      color: #fff;
      font-size: 1.25rem;
    }
    button {
      color: #145fda;
      background-color: #fff;
    }
  }
}
</style>
