<template>
   <div class="our-services-container container development">
      <ComponentHeader :infos="componentHeader" />

      <div class="our-services-inner-wrapper">
         <AccordionContent :items="accordion" />
         <div class="image-wrapper">
            <img src="@/assets/img/our_services.png" alt="" width="500" />
         </div>
      </div>
   </div>
</template>

<script>
import ComponentHeader from "@/components/helpers/ComponentHeader.vue";
import AccordionContent from "@/components/helpers/AccordionContent.vue";
export default {
   name: "OurServices",
   components: {
      ComponentHeader,
      AccordionContent,
   },
   data() {
      return {
         // this.$i18n.t("ourproducts.pills.flight.title"),
         componentHeader: {
            title: this.$i18n.t("ourServices.compTitle"),
            subTitle: this.$i18n.t("ourServices.compSubTitle"),
         },
         accordion: [
            {
               title: this.$i18n.t("ourServices.accordion[0].title"),
               content: this.$i18n.t("ourServices.accordion[0].content"),
            },
            {
               title: this.$i18n.t("ourServices.accordion[1].title"),
               content: this.$i18n.t("ourServices.accordion[1].content"),
            },
            {
               title: this.$i18n.t("ourServices.accordion[2].title"),
               content: this.$i18n.t("ourServices.accordion[2].content"),
            },
         ],
      };
   },
};
</script>

<style lang="scss">
.our-services-container {
   margin-top: 4rem !important;
}
.our-services-inner-wrapper {
   margin-top: 2rem;
   display: flex;
   justify-content: space-between;
   align-items: center;
   @media (max-width: 576px) {
      flex-direction: column;
   }

   > * {
      width: 50%;
      @media (max-width: 576px) {
         width: auto;
      }
   }

   .image-wrapper {
      display: flex;
      justify-content: flex-end;
   }
}
</style>
