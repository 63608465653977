import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./fmt/i18n";
import store from "./store";
import "@/filters/index.js";

import("./assets/css/common.css");

Vue.config.productionTip = false;

// VueAnalytics;
// if (document.cookie.includes("allowCookies=true")) {
//    console.log("truuue");
//    // Configuration VueAnalytics
//    Vue.use(VueAnalytics, {
//       id: "G-D2BJW59Y0S",
//    });
// } else {
//    console.log("false");
//    VueAnalytics;
// }

new Vue({
   router,
   i18n,
   store,
   render: (h) => h(App),
}).$mount("#app");
