<template>
   <div class="our-advantages-container container development">
      <ComponentHeader :infos="componentHeader" />

      <div class="our-advantages-boxes-wrapper">
         <div class="box-item" v-for="(box, index) in boxes" :key="index">
            <div class="box-item-inner">
               <inline-svg @click="handlerSelectItem(index)" :src="require(`@/assets/img/advantages/${box.icon}.svg`)" width="42" height="42"></inline-svg>
               <span class="box-title">{{ box.title }}</span>
               <p class="box-content">{{ box.content }}</p>
            </div>
         </div>

         <div class="image-wrapper">
            <img src="@/assets/img/our_advantages.png" alt="" width="600" height="600" />
         </div>
      </div>
   </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import ComponentHeader from "@/components/helpers/ComponentHeader.vue";

export default {
   name: "OurAdvantages",
   components: {
      ComponentHeader,
      InlineSvg,
   },
   data() {
      return {
         componentHeader: {
            title: this.$i18n.t("ourAdvantages.compTitle"),
         },

         // this.$i18n.t("ourproducts.pills.flight.title"),

         boxes: [
            {
               title: this.$i18n.t("ourAdvantages.boxes[0].title"),
               content: this.$i18n.t("ourAdvantages.boxes[0].content"),
               icon: "1",
            },
            {
               title: this.$i18n.t("ourAdvantages.boxes[1].title"),
               content: this.$i18n.t("ourAdvantages.boxes[1].content"),
               icon: "3",
            },
            {
               title: this.$i18n.t("ourAdvantages.boxes[2].title"),
               content: this.$i18n.t("ourAdvantages.boxes[2].content"),
               icon: "5",
            },
            {
               title: this.$i18n.t("ourAdvantages.boxes[3].title"),
               content: this.$i18n.t("ourAdvantages.boxes[3].content"),
               icon: "4",
            },
            {
               title: this.$i18n.t("ourAdvantages.boxes[4].title"),
               content: this.$i18n.t("ourAdvantages.boxes[4].content"),
               icon: "2",
            },
            {
               title: this.$i18n.t("ourAdvantages.boxes[5].title"),
               content: this.$i18n.t("ourAdvantages.boxes[5].content"),
               icon: "6",
            },
         ],
      };
   },
};
</script>

<style lang="scss">
.our-advantages-boxes-wrapper {
   //    display: grid;
   //    grid-template-columns: 1fr 1fr;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   @media (max-width: 576px) {
      flex-direction: column;
   }

   position: relative;
   .box-item {
      width: 50%;
      //       background: #ccc;
      padding: 0.5rem 2rem;
      @media (max-width: 576px) {
         width: 100%;
      }
      .box-item-inner {
         //  background: red;
         //  border: 1px solid;
         width: inherit;
         display: flex;
         flex-direction: column;
         span {
            &.box-title {
               font-size: 1.35rem;
               margin-top: 1rem;
               font-weight: 500;
            }
         }

         p.box-content {
            padding-top: 1rem;
            line-height: 1.5rem;
            font-weight: 300;
            opacity: 0.8;
         }
      }

      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(6) {
         display: flex;
         justify-content: flex-end;
         //  background: red;
      }
   }
   .image-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 576px) {
         display: none;
      }
   }
}
</style>
