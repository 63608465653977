<template>
   <div class="vertical-tab-wrapper" id="about-us">
      <span class="home-page-section-title">{{ $t("aboutUs.title") }}</span>
      <div class="container gx-0">
         <div class="row gx-0 px-3">
            <div class="col-12">
               <ul class="tabs-container">
                  <li class="tab-row" v-for="(tab, index) in Tabs" :key="index" @click="handlerSelectTab(index)">
                     <div class="title-action">
                        <span class="tab-title">
                           {{ tab.title }}
                        </span>
                        <button>{{ activeTab == index ? "-" : "+" }}</button>
                     </div>
                     <p class="tab-details" v-if="activeTab == index" v-html="tab.content"></p>
                  </li>
               </ul>
            </div>
            <div class="col-6"></div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "VerticalTabs",
   data() {
      return {
         activeTab: 0,
         tabs: [],
      };
   },
   computed: {
      Tabs() {
         return this.tabs;
      },
   },
   methods: {
      handlerSelectTab(index) {
         const self = this;
         self.activeTab = index;
      },
   },
   watch: {
      "$i18n.locale": {
         handler: function () {
            this.tabs = [
               {
                  title: this.$i18n.t("aboutUs.title1"),
                  content: this.$i18n.t("aboutUs.content1"),
                  sign: "+",
               },
               {
                  title: this.$i18n.t("aboutUs.title2"),
                  content: this.$i18n.t("aboutUs.content2"),
                  sign: "+",
               },
               {
                  title: this.$i18n.t("aboutUs.title3"),
                  content: this.$i18n.t("aboutUs.content3"),
                  sign: "+",
               },
               {
                  title: this.$i18n.t("aboutUs.title4"),
                  content: this.$i18n.t("aboutUs.content4"),
                  sign: "+",
               },
               {
                  title: this.$i18n.t("aboutUs.title5"),
                  content: this.$i18n.t("aboutUs.content5"),
                  sign: "+",
               },
            ];
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.vertical-tab-wrapper {
   padding: 0;
   padding-bottom: 10rem;
   padding-top: 10rem;
   background-color: #3164b9;
   //  background-color: #f7f9ff;
   background-color: #387def;
   margin-bottom: 8rem;
   margin-top: 8rem;
   //  color: white;

   .home-page-section-title {
      color: #fff;
   }
   ul.tabs-container {
      width: fit-content;
      width: 100%;
      .tab-row {
         padding: 1rem 0;
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         cursor: pointer;
         //   border-bottom: 1px solid #eee;

         .title-action {
            display: flex;
            align-items: center;
            color: #fff;
            .tab-title {
               font-size: 1.75rem;
               font-weight: 600;
               //  color: #363636;
               width: 100%;
            }
            button {
               background: none !important;
               width: auto;
               padding: 0rem 0.5rem;
               font-size: 1.5rem;
               font-weight: 400;
               border-radius: 0.5rem;
               margin-left: 0.5rem;
               //  color: #333;
               color: #fff;
            }
         }
         .tab-details {
            margin: 0.5rem 0;
            // width: 400px;
            line-height: 1.45;
            color: #fff;
         }

         &:last-child {
            border-bottom: 0;
         }
      }
   }
}
</style>
