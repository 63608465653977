<template>
   <div id="agent-review-wrapper">
      <h3>{{ $t("testimonials.title") }}</h3>

      <div class="container d-flex flex-column justify-content-center">
         <div class="swiper" ref="agentreview">
            <div class="swiper-wrapper">
               <div class="swiper-slide testimonial" v-for="(testimonial, index) in testimonials" :key="index">
                  <section class="col-3 d-flex flex-column align-items-center justify-content-center">
                     <!-- <img v-for="(img, imgindex) in testimonial.testimonial.photo" :key="imgindex" :src="img" class="testimonial-img" /> -->
                     <img :src="testimonial.testimonial.photo" class="testimonial-img" alt="" />
                     <div class="testimonial-info-wrapper">
                        <span class="testimonial-name">{{ testimonial.testimonial.name }}</span>
                        <span class="testimonial-title">{{ testimonial.testimonial.title }}</span>
                     </div>
                  </section>
                  <section class="col-9 d-flex align-items-center justify-content-center">
                     <p class="testimonial-content">
                        {{ testimonial.testimonial.content }}
                     </p>
                  </section>
               </div>
            </div>
            <div class="swiper-pagination"></div>
         </div>
         <div class="swiper-button-prev" @mouseenter="handlerPrevSlide">
            <i></i>
            <div class="button-testimonial-wrapper">
               <span class="button-testimonial-name">{{ slider.prev.name }}</span>
               <span class="button-testimonial-title">{{ slider.prev.title }}</span>
            </div>
         </div>
         <div class="swiper-button-next" @mouseenter="handlerNextSlide" @click="handlerSlideNext">
            <i></i>
            <div class="button-testimonial-wrapper">
               <span class="button-testimonial-name">{{ slider.next.name }}</span>
               <span class="button-testimonial-title">{{ slider.next.title }}</span>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import Swiper, { Navigation, Pagination } from "swiper";

// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);
import "swiper/css";

export default {
   name: "AgentReview",
   props: {
      testimonials: [Array],
   },
   data() {
      return {
         slider: {
            init: null,
            prev: {
               name: null,
               title: null,
            },
            next: {
               name: null,
               title: null,
            },
         },
      };
   },
   mounted() {
      const self = this;
      self.slider.init = new Swiper(self.$refs.agentreview, {
         scrollbar: false,
         speed: 500,
         autoplay: true,
         loop: true,
         spaceBetween: 150,
         pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
         },
         navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
         },
      });

      // console.log(self.slider.init.eventsListeners.activeIndexChange);
   },
   methods: {
      //click Functions
      handlerSlideNext() {
         // const self = this;
         // const activeIndex = self.slider.init.activeIndex + 2;
         // self.slider.next.name = self.testimonials[activeIndex].testimonial.name;
         // self.slider.next.title = self.testimonials[activeIndex].testimonial.title;
      },

      //hover functions
      handlerNextSlide() {
         const self = this;
         const activeIndex = self.slider.init.activeIndex + 1;

         if (self.slider.init.isEnd) {
            self.slider.next.name = "";
            self.slider.next.title = "";
         }

         if (self.testimonials[activeIndex] != undefined) {
            self.slider.next.name = self.testimonials[activeIndex].testimonial.name;
            self.slider.next.title = self.testimonials[activeIndex].testimonial.title;
         }
      },
      handlerPrevSlide() {
         const self = this;
         const activeIndex = self.slider.init.activeIndex - 1;

         if (self.slider.init.isBeginning) {
            self.slider.prev.name = "";
            self.slider.prev.title = "";
         }

         if (self.testimonials[activeIndex] != undefined) {
            self.slider.prev.name = self.testimonials[activeIndex].testimonial.name;
            self.slider.prev.title = self.testimonials[activeIndex].testimonial.title;
         }
      },
   },
   watcher: {
      "slider.init": {
         handler: function () {
            console.log("okoko");
         },
      },
      immediate: true,
   },
};
</script>

<style lang="scss" scoped>
.swiper {
   width: 1000px;
   padding: 3.5rem 6rem;
}

#agent-review-wrapper {
   margin: 7rem 0;
   @media (max-width: 576px) {
      display: none;
   }

   .container {
      position: relative;

      .swiper-button-prev,
      .swiper-button-next {
         background-color: #f8f9fa;
         width: 100px;
         height: 200px;
         position: absolute;
         top: 0;
         bottom: 0;
         margin: auto;
         height: 72px;
         width: 72px;
         white-space: nowrap;
         text-align: left;
         border-radius: 110px;
         display: flex;
         flex-direction: row;
         padding: 0;
         z-index: 999;
         justify-content: center;
         align-items: center;
         transition-property: all;
         transition-timing-function: ease-out;
         transition-duration: 0.25s;
         cursor: pointer;

         i {
            background-image: url("../../assets/img/ico_arrow.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 40px;
            height: 40px;
            flex-shrink: 0;
         }

         .button-testimonial-wrapper {
            display: flex;
            flex-direction: column;
            width: 0px;
            .button-testimonial-name,
            .button-testimonial-title {
               color: #737880;
               font-size: 16px;
            }
            .button-testimonial-name {
               font-weight: 600;
            }
         }

         span {
            opacity: 0;
         }

         &:hover {
            .button-testimonial-wrapper {
               width: auto !important;
            }
            span {
               opacity: 1;
            }

            width: auto;
            padding: 0 1.5rem;
         }
      }
      .swiper-button-prev {
         left: 0;

         i {
            transform: rotate(-180deg);
         }
      }
      .swiper-button-next {
         right: 0;
      }
   }

   h3 {
      color: #216dd9;
      font-weight: 500;
      text-align: center;
      font-size: 26px;
   }

   .testimonial {
      background: #fff;
      width: 800px;
      height: 210px;
      box-shadow: rgba(60, 70, 75, 0.1) 20px 15px 40px;
      display: flex;
      flex-direction: row;
      box-sizing: border-box;
      padding: 2rem 1.5rem;
      border-radius: 8px;

      section {
         &:first-child {
            border-right: 1px solid #f0f0f0;
         }
      }

      .testimonial-content {
         color: #737880;
         font-size: 18px;
         line-height: 1.75rem;
         font-weight: 400;
         padding: 0 2.5rem;
      }

      .testimonial-img {
         width: 110px;
         height: 110px;
         border-radius: 50%;
      }
      .testimonial-info-wrapper {
         display: flex;
         flex-direction: column;
         text-align: center;
         margin-top: 0.75rem;

         .testimonial-name {
            color: #202a38;
            font-size: 16px;
            font-weight: 600;
         }
         .testimonial-title {
            font-size: 12px;
            margin-top: 0.1rem;
         }
      }
   }
}
</style>
