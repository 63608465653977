<template>
   <div id="contact-wrapper">
      <section class="contact-form-container container" v-if="false">
         <!-- Contact Form -->
         <div class="row">
            <div class="col-6 contact-form-text">
               <h1>Become a Client</h1>
               <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ullamcorper, tellus at congue faucibus, ante ipsum molestie nisl, vel
                  ullamcorper mi justo ac sem. Pellentesque sodales convallis ultricies. Integer non tincidunt velit. Fusce nec laoreet nisl, in
                  aliquam risus.
               </p>
            </div>
            <div class="col-6 contact-form">
               <!-- Name Surname & Company Name Inputs -->
               <div class="row">
                  <div class="col-6">
                     <input type="text" placeholder="Name Surname" v-model="contact.name" />
                  </div>
                  <div class="col-6">
                     <input type="text" placeholder="Company Name" v-model="contact.company" />
                  </div>
               </div>

               <!-- Email & Phone Inputs -->
               <div class="row">
                  <div class="col-6">
                     <input type="text" placeholder="email@company.com" v-model="contact.email" />
                  </div>
                  <div class="col-6"><input type="text" placeholder="+90" v-model="contact.phone" /></div>
               </div>

               <!-- TextArea -->
               <div class="row">
                  <div class="col-12">
                     <textarea name="" id="" cols="30" rows="6" v-model="contact.content">Tell us sth. about your project</textarea>
                  </div>
               </div>

               <!-- Submit -->
               <div class="row" id="submit-action">
                  <div class="col-12">
                     <button class="gts-primary" id="send-contact" @click="handlerFormSend">Send</button>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <Jumbotron :data="jumbotron">
         <div class="contact-form">
            <!-- Name Surname & Company Name Inputs -->
            <div class="row">
               <div class="col-6">
                  <input type="text" placeholder="Name Surname" v-model="contact.name" />
               </div>
               <div class="col-6">
                  <input type="text" placeholder="Company Name" v-model="contact.company" />
               </div>
            </div>

            <!-- Email & Phone Inputs -->
            <div class="row">
               <div class="col-6">
                  <input type="text" placeholder="email@company.com" v-model="contact.email" />
               </div>
               <div class="col-6"><input type="text" placeholder="+90" v-model="contact.phone" /></div>
            </div>

            <!-- TextArea -->
            <div class="row">
               <div class="col-12">
                  <textarea name="" id="" cols="30" rows="6" v-model="contact.content">Tell us sth. about your project</textarea>
               </div>
            </div>

            <!-- Submit -->
            <div class="row" id="submit-action">
               <div class="col-12">
                  <button class="gts-primary" id="send-contact" @click="handlerFormSend">Send</button>
               </div>
            </div>
         </div>
      </Jumbotron>
      <hr />
      <section class="our-offices container">
         <!-- Offices -->
         <div class="row" id="offices">
            <span class="title">Our Offices</span>
            <div class="col-6 office">
               <div class="office-container">
                  <span class="office-title">Germany Headquarters</span>
                  <span class="office-address">GTS Global Travel Services GmbH, Stresemannstr. <br />342, 22761, Hamburg</span>
                  <span class="office-telephone">+49 (0) 40/ 22 61 38 37 0</span>
                  <span class="office-email">info@gtsfly.de</span>
               </div>
            </div>
            <div class="col-6 office">
               <div class="office-container">
                  <span class="office-title">Turkey DMC Office</span>
                  <span class="office-address"
                     >Güzeloba Mah. Havaalanı Cad. No:37<br />
                     İç Kapı No:103 Muratpaşa / Antalya PK: 07230</span
                  >
                  <span class="office-telephone">+49 (0) 40/ 22 61 38 37 0</span>
                  <span class="office-email"></span>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>

<script>
import { contactForm } from "@/service/rest.api.js";
import Jumbotron from "@/components/common/Jumbotron.vue";
export default {
   name: "ContactPage",
   components: {
      Jumbotron,
   },
   data() {
      return {
         jumbotron: null,
         contact: {
            name: "",
            company: "",
            email: "",
            phone: "",
            content: "Tell us sth. about your project...",
         },
      };
   },
   created() {
      const self = this;

      self.$store.state.header = "light";

      self.jumbotron = {
         title: "Level up your",
         subtitle: "Business together...",
         content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sit amet lacus nec tellus auctor blandit elementum vel augue. In nisl diam, accumsan at mauris eu, ullamcorper faucibus sapien.",
         content2: null,
      };
   },
   methods: {
      handlerFormSend() {
         const self = this;
         self;

         let query = {
            "your-name": self.contact.name,
            "your-company": self.contact.company,
            "your-email": self.contact.email,
            "your-phone": self.contact.phone,
            "your-content": self.contact.content,
         };

         const form = new FormData();
         for (const field in query) {
            form.append(field, query[field]);
         }

         contactForm(form).then((res) => {
            if (res.status == "mail_sent") {
               console.log("success");
            }
         });
      },
   },
};
</script>

<style lang="scss" scoped>
#contact-wrapper {
   hr {
      border: 0.005rem solid #dbdbdb;
   }
   span.title {
      color: #32b8fb;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.2rem;
      margin-bottom: 1rem;
   }

   .container {
      padding: 5rem 0;
   }

   .our-offices {
      .office {
         transition-property: all;
         transition-timing-function: ease-in-out;
         transition-duration: 0.15s;
         .office-container {
            display: flex;
            flex-direction: column;
            margin-top: 2.5rem;

            span {
               font-size: 1.125rem;
               margin-bottom: 0.75rem;
               color: #44484f;
               &.office-title {
                  font-weight: 500;
                  font-size: 2.125rem;
                  color: #44484f;
                  margin-bottom: 1.5rem;
               }
               &.office-address {
                  line-height: 1.5rem;
                  margin-bottom: 2rem;
               }
            }
         }

         &:hover {
            background-color: #f7f9ff;
         }
      }
   }
}
</style>
