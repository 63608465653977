<template>
   <div id="static-page-wrapper">
      <div class="container">
         <section id="page-header">
            <span class="page-title">{{ title }}</span>
            <span class="page-subtitle">{{ subtitle }}</span>

            <div class="page-content" v-html="content"></div>
         </section>
         <hr />
      </div>
   </div>
</template>

<script>
import * as RestApi from "@/service/rest.api";
export default {
   name: "StaticPage",
   data() {
      return {
         title: "",
         subtitle: "",
         content: "",
      };
   },
   created() {
      const self = this;
      const postID = self.$route.name;

      RestApi.news(postID).then((res) => {
         self.title = res.acf.title;
         self.subtitle = res.acf.subtitle;
         self.content = res.acf.content;
      });
   },
   methods: {
      handlerPageRefresh() {
         const self = this;
         const pageID = self.$route.name;

         RestApi.news(pageID).then((res) => {
            self.title = res.acf.title;
            self.subtitle = res.acf.subtitle;
            self.content = res.acf.content;
         });
      },
   },
   watch: {
      "$route.name": {
         handler: function () {
            const self = this;
            self.handlerPageRefresh();
         },
      },
   },
};
</script>

<style lang="scss" scoped>
#static-page-wrapper {
   margin-top: 50px;
   margin-bottom: 100px;

   #page-header {
      display: flex;
      flex-direction: column;
      .page-title {
         font-size: 3rem;
         font-weight: 600;
         color: #363636;
         margin-bottom: 1.5rem;
      }
      .page-subtitle {
         font-size: 1.25rem;
         font-weight: 400;
         color: #363636;
         opacity: 0.6;
      }
   }

   hr {
      margin: 2rem 0;
   }
}
</style>

<style lang="scss">
.page-content {
   width: 60%;
   p {
      color: #363636;
      font-size: 1rem;
      line-height: 1.65rem;
      b {
         font-size: 1.25rem;
         font-weight: 600;
      }
      a {
         color: #145fda;
         font-weight: 500;
      }
   }
   ol {
      li {
         color: #363636;
         font-size: 1rem;
         line-height: 1.65rem;
      }
   }
}
</style>
