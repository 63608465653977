<template>
   <div class="our-solution-partner container development">
      <ComponentHeader :infos="componentHeader" />

      <div class="swiper" ref="partners">
         <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(partner, index) in partners" :key="index">
               <img :src="require(`@/assets/img/partners/${partner.img}.png`)" height="50px" />
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import Swiper, { Navigation, Pagination } from "swiper";
// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);
import "swiper/css";

import ComponentHeader from "@/components/helpers/ComponentHeader.vue";
export default {
   name: "OurSolutionPartner",
   components: {
      ComponentHeader,
   },
   data() {
      return {
         componentHeader: {
            title: this.$i18n.t("ourSolutionPartner"),
         },
         partners: [
            { img: "1" },
            { img: "2" },
            { img: "3" },
            { img: "4" },
            { img: "5" },
            { img: "6" },
            { img: "7" },
            { img: "8" },
            { img: "9" },
            { img: "10" },
            { img: "11" },
            { img: "12" },
            { img: "13" },
            { img: "14" },
            { img: "15" },
            { img: "16" },
            { img: "17" },
            { img: "18" },
            { img: "19" },
            { img: "20" },
            { img: "21" },
            { img: "22" },
            { img: "23" },
            { img: "24" },
            { img: "25" },
            { img: "26" },
            { img: "27" },
            { img: "28" },
         ],
      };
   },

   mounted() {
      new Swiper(this.$refs.partners, {
         scrollbar: false,
         slidesPerView: 5,
         speed: 5000,
         autoplay: {
            delay: 0,
            disableOnInteraction: false,
         },
         loop: true,
         spaceBetween: 1,
         breakpoints: {
            500: {
               slidesPerView: 2,
            },
            768: {
               slidesPerView: 5,
            },
         },
      });
   },
};
</script>

<style lang="scss" scoped>
.our-solution-partner {
   margin-top: 6rem !important;
   margin-bottom: 3rem !important;
   .swiper {
      margin-top: 2rem;
      cursor: grab;
      padding: 1.5rem 1rem;
      // background: #eeeeee50;
      border: 1px solid #eee;
      border-radius: 1rem;
   }
   .swiper-slide {
      display: flex;
      justify-content: center;
      width: auto !important;
      margin: 0 2rem;
   }
}
</style>
