<template>
   <div class="big-content-viewer developments" :style="{ 'background-image': 'url(' + require('@/assets/img/businessModel.png') + ')' }">
      <div class="container">
         <ComponentHeader :infos="componentHeader" />

         <section class="main-content" v-html="data.content"></section>

         <ul class="big-content-boxes-wrapper">
            <li v-for="(box, index) in data.boxes" :key="index">
               <div class="box-inner-wrapper">
                  <span class="box-title">{{ box.title }}</span>
                  <span class="box-content">{{ box.content }}</span>
               </div>
            </li>
         </ul>
      </div>
   </div>
</template>

<script>
import ComponentHeader from "@/components/helpers/ComponentHeader.vue";
export default {
   name: "BigContentViewer",
   components: {
      ComponentHeader,
   },
   props: {
      data: Object,
   },
   data() {
      return {
         img: "/assets/img/businessModel.png",
         componentHeader: {
            title: this.$i18n.t("businessModel.compTitle"),
            subTitle: this.$i18n.t("businessModel.compSubTitle"),
         },
      };
   },
};
</script>

<style lang="scss" scoped>
.big-content-viewer {
   background-color: #363636;
   background-size: cover;
   padding: 4rem 0rem;

   ::v-deep {
      .comp-title,
      .comp-sub-title {
         color: #ffff;
      }
   }
   .big-content-boxes-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 5rem;
      flex-wrap: wrap;

      @media (max-width: 576px) {
         flex-direction: column;
      }

      li {
         width: 33%;
         height: 100%;
         display: flex;
         flex-shrink: 0;
         padding: 0 1rem;
         @media (max-width: 576px) {
            width: 100%;
            margin-bottom: 1rem;
         }

         .box-inner-wrapper {
            border: 1px solid #fff;
            padding: 2rem 1.5rem;
            border-radius: 1rem;
            display: flex;
            flex-direction: column;
         }
         span {
            width: 100%;
            text-align: center;
            color: #fff;
            &.box-title {
               font-size: 1.35rem;
               padding-bottom: 1rem;
               font-weight: 500;
            }
            &.box-content {
               font-weight: 300;
               font-size: 1rem;
               line-height: 1.5rem;
            }
         }
      }
   }
}
</style>
<style lang="scss">
.main-content {
   margin-top: 3rem;
   p {
      color: #fff;
      font-weight: 300;
      font-size: 1.125rem;
      margin: 1.5rem 0;
   }
}
</style>
