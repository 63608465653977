<template>
   <div id="info-box" @click="handlerApplicationForm">
      <div class="container">
         <!-- <span class="big-info-header">{{ compInfoBox.title }}</span>
      <span class="big-info-sub">{{ compInfoBox.subtitle }}</span> -->
         <span class="big-info-header">{{ $t("infoBox.title") }}</span>
         <span class="big-info-sub">{{ $t("infoBox.subTitle") }}</span>
      </div>
   </div>
</template>

<script>
export default {
   name: "InfoBox",
   props: {
      infoBox: Object,
   },

   computed: {
      compInfoBox() {
         const self = this;
         const lang = localStorage.lang;
         return self.infoBox[lang];
      },
   },
   methods: {
      redirectTo() {
         this.$router.push({
            path: "/blog",
         });
      },
      handlerApplicationForm() {
         this.$store.state.applicationForm = true;
      },
   },
};
</script>

<style lang="scss" scoped>
#info-box {
   position: relative;
   height: 376px;
   border-top: 1px solid;
   border-bottom: 1px solid;
   border-color: #ebecf2;
   padding: 3rem;
   box-sizing: border-box;
   display: flex;
   align-items: center;
   margin-bottom: 5rem;
   cursor: pointer;

   span {
      display: block;
      transition-property: all;
      transition-timing-function: ease-in-out;
      transition-duration: 0.15s;
      color: #202a38;
   }
   .big-info-header {
      font-size: 26px;
      font-weight: 600;
      margin-bottom: 1.5rem;
   }
   .big-info-sub {
      font-size: 52px;
      font-weight: 600;
      max-width: 58%;

      @media (max-width: 576px) {
         width: 100%;
         max-width: 100%;
         font-size: 42px;
      }
   }

   &:after {
      content: "*";
      text-indent: -999em;
      position: absolute;
      background-color: #f7f9ff;
      z-index: -1;
      bottom: 0;
      top: 100%;
      left: 0;
      right: 0;
      transition-property: all;
      transition-timing-function: ease-in-out;
      transition-duration: 0.15s;
   }

   &:hover {
      &:after {
         top: 0;
         bottom: 0;
         background-color: #f7f9ff;
      }

      span {
         color: #216dd9;
      }
   }
}
</style>
