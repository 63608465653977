<template>
   <div id="language-control-container" @click="dropdown = !dropdown">
      <span class="selected-language">{{ Language.toUpperCase() }}</span>
      <ul class="language-control-dropdown" v-if="dropdown">
         <li @click="handlerChangeLanguage('tr')">TR</li>
         <li @click="handlerChangeLanguage('de')">DE</li>
         <li @click="handlerChangeLanguage('en')">EN</li>
      </ul>

      <!-- <select name="lang" id="lang" v-model="language" @change="handlerSelectLanguage">
         <option value="tr">tr</option>
         <option value="de">de</option>
      </select> -->
   </div>
</template>

<script>
export default {
   name: "LanguageControl",
   data() {
      return {
         language: this.$i18n.locale,
         dropdown: false,
      };
   },
   created() {
      //       this.$i18n.locale = "tr";
      //       console.log(this.$i18n.locale);
      // const currentLanguage = localStorage.getItem("lang");
      // console.log("currentLanguage", currentLanguage);
   },
   computed: {
      Language() {
         // console.log(this.$i18n);
         return this.$i18n.locale;
      },
   },
   methods: {
      handlerSelectLanguage() {
         this.$i18n.locale = this.language;
         window.location.reload();
         //  console.log("change", this.language);
      },
      handlerChangeLanguage(lang) {
         this.language = lang;
         this.$i18n.locale = this.language;
         localStorage.setItem("lang", lang);
         window.location.reload();
      },
   },
};
</script>

<style lang="scss" scoped>
#language-control-container {
   position: relative;
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0 1rem;
   border-right: 1px solid #ccc;
   margin-right: 1rem;
   color: #363636;
   font-weight: 600;
   .language-control-dropdown {
      background-color: #fff;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      border: 1px solid #eee;
      border-radius: 0.5rem;
      li {
         display: flex;
         justify-content: center;
         padding: 0.75rem 0.25rem;
         border-bottom: 1px solid #eee;
         &:last-child {
            border-bottom: none;
         }
      }
   }
}
</style>
