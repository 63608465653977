<template>
  <div id="products-wrapper">
    <div class="blue-box-wrapper">
      <div class="container">
        <section class="product-wrapper" v-if="false">
          <span class="product-big-title">GDS Flights</span>
          <span class="product-sub-title">All GDS Flights around the world: Amadeus, Galileo, Saber</span>
          <span class="product-content">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc facilisis turpis aliquet turpis egestas vehicula. Sed id urna nec mauris
            congue vulputate.
          </span>
        </section>

        <section class="product-wrapper-double">
          <div class="product-wrapper-left">
            <span class="product-big-title">Low Cost Carriers</span>
            <span class="product-content">Low Cost Carrier flights operating between Europe, Turkey and CIS countries.</span>
            <a href="">More Detail</a>
          </div>
          <div class="product-wrapper-right">
            <span class="product-big-title">Charter Flights</span>
            <span class="product-content"
              >Charter flights from operators with holiday packages in Europe, Turkey, Middle East and CIS countries.</span
            >
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductPage",
  components: {},
  data() {
    return {
      products: [
        {
          title: "GDS Flights",
          subtitle: "All GDS Flights around the world: Amadeus, Galileo, Saber",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc facilisis turpis aliquet turpis egestas vehicula. Sed id urna nec mauris congue vulputate.",
        },
        {
          title: "GDS Flights",
          subtitle: "All GDS Flights around the world: Amadeus, Galileo, Saber",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc facilisis turpis aliquet turpis egestas vehicula. Sed id urna nec mauris congue vulputate.",
        },
        {
          title: "GDS Flights",
          subtitle: "All GDS Flights around the world: Amadeus, Galileo, Saber",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc facilisis turpis aliquet turpis egestas vehicula. Sed id urna nec mauris congue vulputate.",
        },
      ],
    };
  },
  created() {
    const self = this;
    self.$store.state.header = "blue";
  },
};
</script>

<style lang="scss" scoped>
.blue-box-wrapper {
  padding: 4rem 0;
  background-color: #2268db;
  height: 100vh;

  .product-wrapper {
    display: flex;
    flex-direction: column;
    padding: 5rem 0;
    position: relative;

    span {
      margin-bottom: 1.5rem;
      &.product-big-title {
        font-size: 4rem;
        font-weight: 800;
        color: #fff;
      }
      &.product-sub-title {
        font-size: 2rem;
        font-weight: 600;
        color: #fff;
      }
      &.product-content {
        font-size: 1.5rem;
        font-weight: 300;
        color: #fff;
        line-height: 2rem;
        width: 60%;
      }
    }

    &:after {
      content: "";
      background-image: url("@/assets/img/arrow.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 150px;
      height: 150px;
      position: absolute;
      right: 0;
      opacity: 0.75;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
  .product-wrapper-double {
    margin-top: 5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10%;

    .product-wrapper-left,
    .product-wrapper-right {
      display: flex;
      flex-direction: column;
      span {
        margin-bottom: 1.5rem;
        &.product-big-title {
          font-size: 3rem;
          font-weight: 800;
          color: #fff;
        }
        &.product-sub-title {
          font-size: 2rem;
          font-weight: 600;
          color: #fff;
        }
        &.product-content {
          font-size: 1.5rem;
          font-weight: 300;
          color: #fff;
          line-height: 2.25rem;
        }
      }

      a {
        margin-top: 0.5rem;
        font-size: 1.25rem;
        display: block;
        color: #8bb2f4;
        opacity: 1;
        font-weight: 400;
        width: fit-content;
        padding-bottom: 0.25rem;
        border-bottom: 2px solid #79abff;
      }
    }
  }
}
</style>
