<template>
  <div id="team-wrapper" class="container">
    <span class="section-title">Our Team</span>
    <p class="section-sub-title">Our team consists of expert with years of experience.</p>
    <!-- Team -->
    <div class="team-members-container">
      <div class="team-member" v-for="(member, index) in team" :key="index">
        <div class="img-container" @mouseenter="handlerMemberImage(index)" @mouseleave="handlerMemberImageLeave(index)">
          <img :src="require(`@/assets/img/team/${member.image}.png`)" alt="" :class="!member.isHover ? 'active' : 'deactive'" />
          <img :src="require(`@/assets/img/team/hover/${member.image}_.png`)" alt="" :class="member.isHover ? 'active' : 'deactive'" />
        </div>

        <div class="member-infos">
          <span class="member-name">{{ member.name }}</span>
          <span class="member-title">{{ member.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurTeam",
  data() {
    return {
      team: [
        { name: "Bahadır Gerçek", title: "General Manager", image: "01", isHover: false },
        { name: "Halil Koşar", title: "IT Director", image: "06", isHover: false },
        { name: "Arzu Bostanoğlu", title: "Senior Contract Manager", image: "02", isHover: false },
        { name: "Canan Akol", title: "Accounting Manager", image: "05", isHover: false },
        { name: "Erdem Öztekin", title: "Devops", image: "03", isHover: false },
        { name: "Seçil Güven", title: "Senior Contract Manager", image: "07", isHover: false },
        { name: "Oğuz Özvarna", title: "Operations Manager", image: "04", isHover: false },
        { name: "Şeyma Sözen", title: "Senior Contract Manager", image: "08", isHover: false },
      ],
    };
  },
  methods: {
    handlerMemberImage(index) {
      const self = this;
      self.team[index].isHover = true;
    },
    handlerMemberImageLeave(index) {
      const self = this;
      self.team[index].isHover = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#team-wrapper {
  padding: 7rem 0;

  .section-sub-title {
    font-size: 1.25rem;
    line-height: 1.75rem;
    width: 50%;
  }
  .team-members-container {
    // background: lightblue;
    margin-top: 3rem;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 4rem;
    row-gap: 5rem;
    box-sizing: content-box;
    .team-member {
      .img-container {
        overflow: hidden;
        border-radius: 0.5rem;
        height: fit-content;

        img {
          width: 100%;
          height: 100%;
          transform: scale(1);
          filter: saturate(0);

          transition: all;
          transition-duration: 0.1s;
          transition-timing-function: ease-out;

          &.active {
          }
          &.deactive {
            display: none;
          }
        }
      }

      .member-infos {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        justify-content: center;
        align-content: center;
        span.member-name {
          font-size: 1.5rem;
          font-weight: 500;
          color: #363636;
          margin-bottom: 0.25rem;
          text-align: center;
        }
        span.member-title {
          text-align: center;
          font-size: 1.125rem;
          color: #363636;
          opacity: 0.8;
        }
      }

      &:hover {
        img {
          transform: scale(1);
          filter: saturate(1.25);
        }
      }
    }
  }
}
</style>
