<template>
   <div id="about-us-wrapper">
      <Jumbotron :data="jumbotron" v-if="true" />
      <VerticalTabs />

      <OurStory v-if="false" />

      <hr />
      <!-- Agents, Airlines, Hotels -->
      <div id="informations" class="container">
         We are partner with <span>1500</span> active agents from different countries <br />
         and we provide <span>600</span> airline companies & <span>1000</span> hotels for their business.
      </div>
      <hr />

      <OurTeam />

      <AgentApllicationCallback />
   </div>
</template>

<script>
import Jumbotron from "@/components/common/Jumbotron.vue";
import VerticalTabs from "@/components/common/VerticalTab.vue";
import OurStory from "@/components/AboutUs/OurStory.vue";
import OurTeam from "@/components/AboutUs/Team.vue";
import AgentApllicationCallback from "@/components/common/AgentApplicationCallback.vue";
export default {
   name: "AboutUs",
   components: {
      Jumbotron,
      VerticalTabs,
      OurStory,
      OurTeam,
      AgentApllicationCallback,
   },
   data() {
      return {
         jumbotron: null,
      };
   },
   created() {
      const self = this;
      self.$store.state.header = "light";
      self.jumbotron = {
         title: "New generation",
         subtitle: "B2B travel platform.",
         content:
            'GTS, <span class="jumbotron-mini-information">Global Travel Services</span>, is a Germany based online travel platform that consists of all the travel products agents require for their business.',
         content2:
            "Products gathered from different providers enable agents to compare prices and benefits of each travel products. Agents can serve to their customers needs with flexible alternatives.",
      };
   },
};
</script>

<style lang="scss" scoped>
#about-us-wrapper {
   // margin-bottom: 10rem;
   #informations {
      padding: 8rem 0;
      font-family: "Merriweather", serif;
      font-size: 2.25rem;
      line-height: 3.5rem;
      text-align: center;
      color: #363636;
      font-weight: 300;
      span {
         font-weight: 600;
      }
   }
}
</style>
