<template>
   <header id="header-wrapper" :class="HeaderTheme">
      <div id="header-container" class="container">
         <router-link to="/">
            <section id="header-brand-logo">
               <InlineSvg
                  :src="require('@/assets/img/brand_logo.svg')"
                  :fill="LogoColor"
                  aria-label="Brand Logo"
                  class="brand-logo d-none d-md-block" />
               <InlineSvg
                  :src="require('@/assets/img/brand_logo_mobile.svg')"
                  :fill="LogoColor"
                  aria-label="Brand Logo"
                  class="mobile-brand-logo d-md-none" />
               <a :href="hiring.link" class="hiring-label" v-if="false">We're hiring!</a>
            </section>
         </router-link>

         <section id="header-menu-items" v-if="false">
            <div class="header-menu-item-container" v-for="(item, index) in items" :key="index" @click="handlerClickRouter(item.slug)">
               <router-link :to="item.slug == 'agent-application' ? '#application-form' : item.slug">
                  {{ item.title }}
               </router-link>
            </div>
         </section>
         <section id="header-menu-items" class="static" v-if="false">
            <div class="header-menu-item-container">
               <a href="#about-us">{{ $t("header.aboutus") }} </a>
               <a href="#announcements-and-news-container">{{ $t("header.news") }} </a>
               <a @click="handlerApplicationForm">{{ $t("header.application") }} </a>
               <!-- <router-link to="#we-are-growing-container"> About Us </router-link> -->
               <!-- <router-link to="/"> Contact </router-link> -->
            </div>
         </section>

         <section id="header-actions">
            <LanguageControl />
            <button @click="handlerSiteMode" v-if="false">{{ compSiteMode }}</button>

            <button class="gts-primary" @click="handlerAgentLogin">{{ $t("header.agentLogin") }}</button>
         </section>
      </div>
   </header>
</template>

<script>
// import { primaryMenu } from "@/service/rest.api.js";
import InlineSvg from "vue-inline-svg";
import LanguageControl from "@/components/structural/header/LanguageControl.vue";
export default {
   name: "HeaderMenu",
   components: {
      InlineSvg,
      LanguageControl,
   },
   data() {
      return {
         items: [],
         hiring: {
            statue: false,
            link: "",
         },
      };
   },

   created() {
      const self = this;

      self.sitemode = self.$store.state.sitemode;

      // primaryMenu().then((res) => {
      //    res.forEach((item) => {
      //       if (item.attr_title.length != 0) {
      //          if (item.attr_title == "Career") {
      //             self.hiring.statue = true;
      //             self.hiring.link = item.url;
      //          }
      //       } else {
      //          self.items.push(item);
      //       }
      //    });
      // });
   },

   computed: {
      compSiteMode() {
         const self = this;
         return self.$store.state.sitemode;
      },
      HeaderTheme() {
         const self = this;
         return self.$store.state.header;
      },
      LogoColor() {
         const self = this;
         if (self.HeaderTheme == "light") {
            return "#2268db";
         } else {
            return "#FFFFFF";
         }
      },
   },
   methods: {
      handlerApplicationForm() {
         this.$store.state.applicationForm = true;
      },
      handlerClickRouter(slug) {
         const self = this;

         if (slug == "agent-application") {
            self.$store.state.applicationForm = true;
         }
      },
      handlerAgentLogin() {
         window.open("https://agent.gtsfly.de");
      },
      handlerSiteMode() {
         const self = this;
         self;
         if (self.compSiteMode == "light") {
            self.$store.state.sitemode = "dark";
         } else {
            self.$store.state.sitemode = "light";
         }
         document.querySelector("body").removeAttribute("class");
         document.querySelector("body").classList.add(self.$store.state.sitemode);
         document.cookie = `sitemode=${self.$store.state.sitemode}`;
      },
   },
   watch: {
      sitemode: {
         handler: function () {
            const self = this;
            self.sitemode = self.$store.state.sitemode;
         },
      },
   },
};
</script>

<style lang="scss" scoped>
#header-wrapper {
   height: 100px;
   position: fixed;
   width: 100%;
   z-index: 999;
   top: 0;
   display: flex;
   // padding-top: 0.5rem;
   #header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      #header-menu-items {
         display: flex;
         height: 100%;
         align-items: center;
         .header-menu-item-container {
            // height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin: 0 0.5rem;
            cursor: pointer;
            position: relative;
            a {
               padding: 0.75rem 1rem;
               position: relative;
               text-decoration: none;
               text-transform: uppercase;
               font-weight: 500;
               // &:before {
               //    content: "*";
               //    text-indent: -999em;
               //    background: #f1f1f1;
               //    position: absolute;
               //    left: 0;
               //    right: 0;
               //    bottom: 0;
               //    height: 0;
               //    z-index: -1;
               //    border-radius: 6px;
               //    transition-property: all;
               //    transition-timing-function: ease-in-out;
               //    transition-duration: 0.1s;
               // }
            }

            &:hover {
               // a {
               //    &:before {
               //       content: "*";
               //       height: 100%;
               //    }
               // }
            }
            .has-sub-menu {
               position: absolute;
               display: none;
               background: #fff;
               padding: 0.5rem;
               border-bottom-left-radius: 4px;
               border-bottom-right-radius: 4px;
               top: 80px;
               left: 0;
               box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 7px;

               li {
                  white-space: nowrap;
                  margin: 0.5rem 0;
               }
            }
         }
      }
      #header-brand-logo {
         position: relative;

         a {
            position: absolute;
            white-space: nowrap;
            background-color: #33b8fb;
            padding: 0 0.25rem;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 24px;
            line-height: 24px;
            color: #fff;
            text-transform: lowercase;
            font-size: 0.85rem;
            letter-spacing: 0.02rem;
            margin-left: 1.25rem;

            &:before {
               content: "";
               position: absolute;
               left: 0;
               width: 0;
               height: 0;
               border-color: transparent #33b8fb transparent transparent;
               border-style: solid;
               border-width: 12px 0.35rem 12px 0;
               content: "";
               -webkit-transform: translateX(-100%);
               transform: translateX(-100%);
            }
         }

         svg {
            width: auto;
            height: 60px;
         }
      }
      #header-actions {
         display: flex;
      }
   }

   &.light {
      background-color: #ffffff;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 7px;
      .header-menu-item-container {
         a {
            color: #8b9096;
         }
      }
   }
   &.blue {
      background-color: #2268db;

      .header-menu-item-container {
         a {
            color: #fff !important;

            &:before {
               content: "*";

               background: #1b5dcb !important;
            }
         }
      }
   }
}
</style>
